import React, { Component } from 'react';
import {
  IonButton,
  IonCard,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonRouterLink,
  isPlatform,
} from '@ionic/react';
import { chevronUpOutline, home, megaphoneOutline, searchOutline } from 'ionicons/icons';
import { Helmet } from 'react-helmet';
import { CategoriesListSearch, Footer, Header, MainResults, PopularTags, Search as SearchF } from 'components';
import { constants, Events } from 'utils';
import './Search.scss';
import { RouteComponentProps, withRouter } from 'react-router';
// @ts-ignore
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { Category, Region, SearchCriteria } from 'utils/Types/types';
import { trackEvent } from '../../tracking';
import { searchButtonClicked } from '../../tracking/events';
import { getSEOParametre, isProduction, setLanguage } from 'utils/Functions/Functions';
import { connect } from 'react-redux';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';
import PremiumPostsSlide from '../../components/PremiumPostsSlide/PremiumPostsSlide';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { AdSlot, DFPSlotsProvider } from 'react-dfp';
import qs from 'query-string';
import { getCategoriesReduxUtils, getParametrageReduxByCleUtils } from 'utils/Functions/ReferentialGetters';
import AccordionFAQ from '../../components/AccordionFAQ/AccordionFAQ';
import PremiumAnnonces from 'components/PremiumAnnonces/PremiumAnnonces';
import { increment, setValue } from '../../slices/searchRefreshCounterSlice';
import EventTrackingService from '../../services/EventTrackingService';

const regionCategorySearchKey = '@flaha.regionCategorySearch';

class Search extends Component<Props, State> {
  private mainSearchResults: any | null = null;
  private myRef;

  constructor(props: Props, context: any) {
    super(props, context);

    this.myRef = React.createRef();

    const { match, location } = props;
    const { region, category } = match.params;
    const { am, bv, mar, maxP, minP, mod, pf, tc } = qs.parse(location.search);

    let query = match.params.query;
    query = query ? query.replace(/_/g, ' ') : null;
    const lang = window.location.href.includes('/fr/') ? 'fr' : 'ar';

    const categoriesRedux = getCategoriesReduxUtils();
    let tmpCategoryObject = undefined;
    if (category) {
      tmpCategoryObject =
        lang === 'ar'
          ? categoriesRedux?.find((item) => item.urlArabe === category)
          : categoriesRedux?.find((item) => item.urlFrancais === category);
    }

    let tmpRegion = region ? region : constants.allRegion,
      tmpCategory = category ? category : constants.allCategories,
      tmpQuery = query ? query : null,
      priceTmp = {
        lower: minP ? +minP : null,
        upper: maxP ? +maxP : null,
      },
      titleOnlyTmp = false,
      urgentOnlyTmp = false,
      showAdvanceSearchTmp = false,
      tmpMarque = mar ? String(mar) : null,
      tmpModel = mod ? String(mod) : null,
      tmpTypeCarburant = tc ? String(tc) : undefined,
      tmpBoiteVitesse = bv ? String(bv) : undefined,
      tmpMarqueObject = undefined,
      tmpModelObject = undefined,
      tmpTypeCarburantObject = undefined,
      tmpBoiteVitesseObject = undefined,
      tmpPuissanceFiscale = pf ? String(pf) : undefined,
      tmpAnneeModele = am ? String(am) : undefined;

    if (location.state) {
      const {
        // @ts-ignore
        region,
        // @ts-ignore
        category,
        // @ts-ignore
        query,
        // @ts-ignore
        price,
        // @ts-ignore
        titleOnly,
        // @ts-ignore
        urgentOnly,
        // @ts-ignore
        showAdvanceSearch,
        // @ts-ignore
        marque,
        // @ts-ignore
        modele,
        // @ts-ignore
        typeCarburant,
        // @ts-ignore
        boiteVitesse,
        // @ts-ignore
        puissanceFiscale,
        // @ts-ignore
        anneeModele,
        // @ts-ignore
        categoryObject,
        // @ts-ignore
        marqueObject,
        // @ts-ignore
        modeleObject,
        // @ts-ignore
        boiteVitesseObject,
        // @ts-ignore
        typeCarburantObject,
      } = location.state;
      tmpRegion = region ? region : tmpRegion;
      tmpCategory = category ? category : tmpCategory;
      tmpQuery = query ? query : tmpQuery || undefined;
      priceTmp = price;
      titleOnlyTmp = titleOnly;
      urgentOnlyTmp = urgentOnly;
      showAdvanceSearchTmp = showAdvanceSearch;
      tmpMarque = marque;
      tmpModel = modele;
      tmpTypeCarburant = typeCarburant;
      tmpBoiteVitesse = boiteVitesse;
      tmpCategoryObject = categoryObject;
      tmpMarqueObject = marqueObject;
      tmpModelObject = modeleObject;
      tmpTypeCarburantObject = typeCarburantObject;
      tmpBoiteVitesseObject = boiteVitesseObject;
      tmpPuissanceFiscale = puissanceFiscale;
      tmpAnneeModele = anneeModele;
    }
    this.props.setShowModal(false);
    this.props.setShowSearchModal(false);
    this.state = {
      lang: 'ar',
      region: tmpRegion,
      category: tmpCategory,
      query: tmpQuery,
      price: priceTmp,
      titleOnly: titleOnlyTmp,
      urgentOnly: urgentOnlyTmp,
      showAdvanceSearch: showAdvanceSearchTmp,
      results: 0,
      searchClicked: 0,
      metaTitle: ['', ''],
      metaDescription: ['', ''],
      regionTitreFr: '',
      regionTitreAr: '',
      categoryTitreFr: '',
      categoryTitreAr: '',
      totalElements: 0,
      marque: tmpMarque,
      modele: tmpModel,
      typeCarburant: tmpTypeCarburant,
      boiteVitesse: tmpBoiteVitesse,
      categoryObject: tmpCategoryObject,
      marqueObject: tmpMarqueObject,
      modeleObject: tmpModelObject,
      typeCarburantObject: tmpTypeCarburantObject,
      boiteVitesseObject: tmpBoiteVitesseObject,
      puissanceFiscale: tmpPuissanceFiscale,
      anneeModele: tmpAnneeModele,
      regionObject: null,
    };
    this.getAccountAnnonces();
  }

  langChange() {
    const { match } = this.props;
    // @ts-ignore
    const lang = match.params.lang;
    this.setState({ lang: lang });
    setLanguage(lang)
      .then((data) => {
        Events.publish(constants.lang_change, { lang: lang });
      })
      .catch((error) => {
        console.error(`Error has been thrown (${error.message})`, error);
      });
  }

  scrollToTop() {
    this.myRef.current && this.myRef.current.scrollToTop(1000);
    if (!this.props.showSearchModalRedux) {
      this.props.setShowSearchModal(true);
    }
  }

  checkLinkLangRedirectionArFr() {
    const pathname = decodeURIComponent(window.location.pathname);
    const { match, categoriesRedux, regionsRedux, citiesRedux, provincesRedux } = this.props;
    // @ts-ignore
    const lang = match.params.lang;
    let { region, category } = this.state;
    const allRegionAr = 'المغرب';
    const allRegionFr = 'maroc';
    const allCategoriesAr = 'للبيع';
    const allCategoriesFr = 'a_vendre';
    //check if url contains arabic or french
    const patternAr = /[\u0600-\u06FF\u0750-\u077F]/;
    const regionIsArabic = patternAr.test(region);
    const categoryIsArabic = patternAr.test(category);
    let categoryRedux;
    let regionRedux;
    if (region && region !== allRegionFr && region !== allRegionAr && regionsRedux && citiesRedux && provincesRedux) {
      regionRedux = regionIsArabic
        ? regionsRedux.find((item) => item.urlArab === region)
        : regionsRedux.find((item) => item.urlFr.toLowerCase() === region.toLowerCase());

      const cityRedux = regionIsArabic
        ? citiesRedux.find((item) => item.urlArab === region)
        : citiesRedux.find((item) => item.urlFr.toLowerCase() === region.toLowerCase());
      const provinceRedux = regionIsArabic
        ? provincesRedux.find((item) => item.urlArab === region)
        : provincesRedux.find((item) => item.urlFr.toLowerCase() === region.toLowerCase());

      if (cityRedux) {
        regionRedux = cityRedux;
      } else if (provinceRedux) {
        regionRedux = provinceRedux;
      }
    }
    //search both arabic and french
    if (category && category !== allCategoriesAr && category !== allCategoriesFr && categoriesRedux) {
      categoryRedux = categoryIsArabic
        ? categoriesRedux.find((item) => item.urlArabe === category)
        : categoriesRedux.find((item) => item.urlFrancais.toLowerCase() === category.toLowerCase());
    }
    if (regionIsArabic && categoryIsArabic && lang === 'fr') {
      window.location.href = pathname.replace('fr', 'ar');
    } else if (regionIsArabic && !categoryIsArabic && lang === 'fr') {
      regionRedux
        ? (window.location.href = pathname.replace(region, regionRedux.urlFr))
        : (window.location.href = pathname.replace(allRegionAr, allRegionFr));
    } else if (!regionIsArabic && categoryIsArabic && lang === 'fr') {
      categoryRedux
        ? (window.location.href = pathname.replace(category, categoryRedux.urlFrancais))
        : (window.location.href = pathname.replace(allCategoriesAr, allCategoriesFr));
    } else if (!regionIsArabic && !categoryIsArabic && lang === 'ar') {
      window.location.href = pathname.replace('ar', 'fr');
    } else if (!regionIsArabic && categoryIsArabic && lang === 'ar') {
      regionRedux
        ? (window.location.href = pathname.replace(region, regionRedux.urlArab))
        : (window.location.href = pathname.replace(allRegionFr, allRegionAr));
    } else if (regionIsArabic && !categoryIsArabic && lang === 'ar') {
      categoryRedux
        ? (window.location.href = pathname.replace(category, categoryRedux.urlArabe))
        : (window.location.href = pathname.replace(allCategoriesFr, allCategoriesAr));
    }
  }

  getAccountAnnonces() {
    const {
      query,
      price,
      titleOnly,
      urgentOnly,
      type,
      marque,
      modele,
      typeCarburant,
      boiteVitesse,
      puissanceFiscale,
      anneeModele,
    } = this.state;
    const { match } = this.props;
    // @ts-ignore
    const lang = match.params.lang;
    let { region, category } = this.state;
    let url = process.env.REACT_APP_API_URL + constants.countAnnonces;

    let filter = {};
    const allRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';

    let categoryRedux;
    let isCity: boolean;
    let isProvince: boolean;
    const { regionsRedux, citiesRedux, provincesRedux } = this.props;
    if (region && region !== allRegion && regionsRedux && citiesRedux && provincesRedux) {
      const regionRedux =
        lang === 'ar'
          ? regionsRedux.find((item) => item.urlArab === region)
          : regionsRedux.find((item) => item.urlFr === region);
      const cityRedux =
        lang === 'ar'
          ? citiesRedux.find((item) => item.urlArab === region)
          : citiesRedux.find((item) => item.urlFr === region);
      const provinceRedux =
        lang === 'ar'
          ? provincesRedux.find((item) => item.urlArab === region)
          : provincesRedux.find((item) => item.urlFr === region);

      if (regionRedux) {
        region = regionRedux.id;
        isCity = false;
        isProvince = false;
      } else if (cityRedux) {
        region = cityRedux.id;
        isCity = true;
        isProvince = false;
      } else if (provinceRedux) {
        region = provinceRedux.id;
        isCity = false;
        isProvince = true;
      }
    }
    //search both arabic and french
    const { categoriesRedux } = this.props;
    if (category && category !== allCategories && categoriesRedux) {
      lang === 'ar'
        ? (categoryRedux = categoriesRedux.find((item) => item.urlArabe === category))
        : (categoryRedux = categoriesRedux.find((item) => item.urlFrancais === category));
      category = categoryRedux ? categoryRedux.id : null;
    }
    const minPrice = get(price, 'lower');
    const maxPrice = get(price, 'upper');
    filter = {
      prixMin: !isNil(minPrice) && !isNil(maxPrice) ? minPrice : null,
      prixMax: !isNil(maxPrice) ? maxPrice : null,
      searchText: query !== '' ? query : null,
      categorieId: category !== '' && category !== allCategories ? category : null,
      urgent: urgentOnly || false,
      titleOnly: titleOnly || false,
      regionId: !isCity && !isProvince && region && region !== allRegion ? region : null,
      villeId: isCity && !isProvince ? region : null,
      provinceId: !isCity && isProvince ? region : null,
      typeAnnonce: type && type !== '' ? type : null,
      marque: marque || null,
      modele: modele || null,
      typeCarburant: typeCarburant || null,
      boiteVitesse: boiteVitesse || null,
      puissanceFiscale: puissanceFiscale || null,
      anneeModele: anneeModele || null,
    };

    const headers = new Headers({
      'Content-Type': 'application/json',
      SameSite: 'secure',
    });
    const filterUri = encodeURIComponent(JSON.stringify(filter));

    url = `${url}` + '?filter=' + filterUri;
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      //mode: 'no-cors',
      headers: headers,
    })
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        if (jsonResult) {
          this.setState(
            {
              totalElements: jsonResult,
            },
            () => {}
          );
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getMarques() {
    const { category } = this.state;
    const { categoriesRedux } = this.props;
    let categoryRedux;
    const lang = localStorage.getItem('@flaha.language');
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    if (category && category !== allCategories && categoriesRedux) {
      categoryRedux =
        lang === 'ar'
          ? categoriesRedux.find((item) => item.urlArabe === category)
          : categoriesRedux.find((item) => item.urlFrancais.toLowerCase() === category.toLowerCase());
    }

    if (categoryRedux && categoryRedux.marques !== null && categoryRedux.marques !== '') {
      const idsMarques = categoryRedux.marques;
      // //////////////console.log('getMarque called !');
      let url = process.env.REACT_APP_API_URL + constants.getMarques;
      const headers = new Headers({
        'Content-Type': 'application/json',
        accept: '*/*',
      });
      fetch(`${url}` + `?marques=${idsMarques}`, {
        method: 'POST',
        headers: headers,
      }).then((response) => {
        let marquesData = response.json();
        marquesData.then((jsonRequest) => {
          if (jsonRequest) {
            localStorage.setItem(
              '@flaha.marques',
              JSON.stringify({
                marques: jsonRequest,
              })
            );
          }
        });
      });
    }
  }

  componentDidMount() {
    if (this.props.pageRedux >= 0) this.props.increment();
    else this.props.setCounterValue(0);
    // this.props.increment();
    this.langChange();
    this.checkLinkLangRedirectionArFr();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    this.importSEOParametres();
    this.getRegionCategory();
    this.getMarques();
  }

  componentWillUnmount() {
    Events.destroy(constants.lang_change);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchClicked !== this.state.searchClicked) {
      this.importSEOParametres();
      this.getAccountAnnonces();
      this.getRegionCategory();
      this.getMarques();
    }
  }

  importSEOParametres() {
    const { query, category, region } = this.state;
    // @ts-ignore
    const lang = this.props.match.params.lang;
    const categoriesRedux = this.props.categoriesRedux;
    let categoryRedux;

    if (categoriesRedux) {
      lang === 'ar'
        ? (categoryRedux = categoriesRedux.find((item) => item.urlArabe === category))
        : (categoryRedux = categoriesRedux.find((item) => item.urlFrancais === category));
    }

    if (lang === 'fr') {
      if (query !== undefined && query !== '' && query !== null) {
        if (category !== 'a_vendre' && region === 'maroc') {
          getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        } else if (category === 'a_vendre' && region !== 'maroc') {
          getParametrageReduxByCleUtils('SEO_SEARCH_VILLE_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        } else if (category !== 'a_vendre' && region !== 'maroc') {
          getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        } else {
          getParametrageReduxByCleUtils('SEO_SEARCH_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        }
        getParametrageReduxByCleUtils('SEO_SEARCH_QUERY_DESCRIPTION').then((value) => {
          if (value) {
            this.setState({ metaDescription: [value.valeurFr, value.valeurAr] });
          }
        });
      } else if (category !== 'a_vendre' && region === 'maroc') {
        if (categoryRedux) {
          if (categoryRedux.id === 117 || categoryRedux.id === 116 || categoryRedux.id === 130) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_TITLE_OFFER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else if (categoryRedux.id === 122) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_TITLE_OTHER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_TITLE').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          }
        }
        this.setState({
          metaDescription: [
            categoryRedux ? categoryRedux.metaDescriptionFr : '',
            categoryRedux ? categoryRedux.metaDescriptionAr : '',
          ],
        });
      } else if (category === 'a_vendre' && region !== 'maroc') {
        getParametrageReduxByCleUtils('SEO_SEARCH_VILLE_TITLE').then((value) => {
          if (value) {
            this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
          }
        });
        getParametrageReduxByCleUtils('SEO_SEARCH_VILLE_DESCRIPTION').then((value) => {
          if (value) {
            this.setState({ metaDescription: [value.valeurFr, value.valeurAr] });
          }
        });
      } else if (category !== 'a_vendre' && region !== 'maroc') {
        if (categoryRedux) {
          if (categoryRedux.id === 117 || categoryRedux.id === 116 || categoryRedux.id === 130) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_TITLE_OFFER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else if (categoryRedux.id === 122) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_TITLE_OTHER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_TITLE').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          }
        }
        this.setState({
          metaDescription: [
            categoryRedux ? categoryRedux.metaDescriptionFr + ' ({0})' : '',
            categoryRedux ? categoryRedux.metaDescriptionAr + ' ({0})' : '',
          ],
        });
      } else if (
        category === 'a_vendre' &&
        region === 'maroc' &&
        (query === undefined || query === null || query === '')
      ) {
        getParametrageReduxByCleUtils('SEO_SEARCH_ALL_TITLE').then((value) => {
          if (value) {
            this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
          }
        });
        getParametrageReduxByCleUtils('SEO_SEARCH_ALL_DESCRIPTION').then((value) => {
          if (value) {
            this.setState({ metaDescription: [value.valeurFr, value.valeurAr] });
          }
        });
      }
    } else {
      if (query !== undefined && query !== '' && query !== null) {
        if (category !== 'للبيع' && region === 'المغرب') {
          getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        } else if (category === 'للبيع' && region !== 'المغرب') {
          getParametrageReduxByCleUtils('SEO_SEARCH_VILLE_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        } else if (category !== 'للبيع' && region !== 'المغرب') {
          getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        } else {
          getParametrageReduxByCleUtils('SEO_SEARCH_QUERY_TITLE').then((value) => {
            if (value) {
              this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
            }
          });
        }
        getParametrageReduxByCleUtils('SEO_SEARCH_QUERY_DESCRIPTION').then((value) => {
          if (value) {
            this.setState({ metaDescription: [value.valeurFr, value.valeurAr] });
          }
        });
      } else if (category !== 'للبيع' && region === 'المغرب') {
        if (categoryRedux) {
          if (categoryRedux.id === 117 || categoryRedux.id === 116 || categoryRedux.id === 130) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_TITLE_OFFER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else if (categoryRedux.id === 122) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_TITLE_OTHER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_TITLE').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          }
        }
        this.setState({
          metaDescription: [
            categoryRedux ? categoryRedux.metaDescriptionFr : '',
            categoryRedux ? categoryRedux.metaDescriptionAr : '',
          ],
        });
      } else if (category === 'للبيع' && region !== 'المغرب') {
        getParametrageReduxByCleUtils('SEO_SEARCH_VILLE_TITLE').then((value) => {
          if (value) {
            this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
          }
        });
        getParametrageReduxByCleUtils('SEO_SEARCH_VILLE_DESCRIPTION').then((value) => {
          if (value) {
            this.setState({ metaDescription: [value.valeurFr, value.valeurAr] });
          }
        });
      } else if (category !== 'للبيع' && region !== 'المغرب') {
        if (categoryRedux) {
          if (categoryRedux.id === 117 || categoryRedux.id === 116 || categoryRedux.id === 130) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_TITLE_OFFER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else if (categoryRedux.id === 122) {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_TITLE_OTHER').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          } else {
            getParametrageReduxByCleUtils('SEO_SEARCH_CATEGORY_VILLE_TITLE').then((value) => {
              if (value) {
                this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
              }
            });
          }
        }
        this.setState({
          metaDescription: [
            categoryRedux ? categoryRedux.metaDescriptionFr + ' ({0})' : '',
            categoryRedux ? categoryRedux.metaDescriptionAr + ' ({0})' : '',
          ],
        });
      } else if (
        category === 'للبيع' &&
        region === 'المغرب' &&
        (query === undefined || query === null || query === '')
      ) {
        getParametrageReduxByCleUtils('SEO_SEARCH_ALL_TITLE').then((value) => {
          if (value) {
            this.setState({ metaTitle: [value.valeurFr, value.valeurAr] });
          }
        });
        getParametrageReduxByCleUtils('SEO_SEARCH_ALL_DESCRIPTION').then((value) => {
          if (value) {
            this.setState({ metaDescription: [value.valeurFr, value.valeurAr] });
          }
        });
      }
    }
  }

  async getRegionCategory() {
    const { region, category } = this.state;
    // @ts-ignore
    const lang = this.props.match.params.lang;
    const regionsRedux = this.props.regionsRedux;
    const citiesRedux = this.props.citiesRedux;
    const provincesRedux = this.props.provincesRedux;
    const categoriesRedux = this.props.categoriesRedux;

    let regionRedux;
    let cityRedux;
    let provinceRedux;
    let categoryRedux;

    if (regionsRedux) {
      regionRedux =
        lang === 'ar'
          ? regionsRedux.find((item) => item.urlArab === region)
          : regionsRedux.find((item) => item.urlFr === region);
    }
    if (citiesRedux) {
      cityRedux =
        lang === 'ar'
          ? citiesRedux.find((item) => item.urlArab === region)
          : citiesRedux.find((item) => item.urlFr === region);
    }
    if (provincesRedux) {
      provinceRedux =
        lang === 'ar'
          ? provincesRedux?.find((item) => item.urlArab === region)
          : provincesRedux?.find((item) => item.urlFr === region);
    }
    if (categoriesRedux) {
      lang === 'ar'
        ? (categoryRedux = categoriesRedux.find((item) => item.urlArabe === category))
        : (categoryRedux = categoriesRedux.find((item) => item.urlFrancais === category));
    }

    if (cityRedux) {
      regionRedux = cityRedux;
    } else if (provinceRedux) {
      regionRedux = provinceRedux;
    }

    this.setState({
      regionTitreFr: region,
      regionTitreAr: region,
      categoryTitreFr: category,
      categoryTitreAr: category,
    });
    if (lang === 'fr') {
      if (region !== 'maroc' && category === 'a_vendre' && regionRedux) {
        this.setState({
          regionTitreFr: regionRedux.urlFr,
          regionTitreAr: regionRedux.urlArab,
          categoryTitreFr: 'a_vendre',
          categoryTitreAr: 'للبيع',
          regionObject: regionRedux,
        });
      } else if (region === 'maroc' && category !== 'a_vendre' && categoryRedux) {
        this.setState({
          categoryTitreFr: categoryRedux.urlFrancais,
          categoryTitreAr: categoryRedux.urlArabe,
          regionTitreFr: 'maroc',
          regionTitreAr: 'المغرب',
        });
      } else if (region !== 'maroc' && category !== 'a_vendre' && categoryRedux && regionRedux) {
        this.setState({
          categoryTitreFr: categoryRedux.urlFrancais,
          categoryTitreAr: categoryRedux.urlArabe,
          regionTitreFr: regionRedux.urlFr,
          regionTitreAr: regionRedux.urlArab,
          regionObject: regionRedux,
        });
      } else if (region === 'maroc' && category === 'a_vendre') {
        this.setState({
          categoryTitreFr: 'a_vendre',
          categoryTitreAr: 'للبيع',
          regionTitreFr: 'maroc',
          regionTitreAr: 'المغرب',
        });
      }
    } else {
      if (region !== 'المغرب' && category === 'للبيع' && regionRedux) {
        this.setState({
          regionTitreFr: regionRedux.urlFr,
          regionTitreAr: regionRedux.urlArab,
          categoryTitreFr: 'a_vendre',
          categoryTitreAr: 'للبيع',
          regionObject: regionRedux,
        });
      } else if (region === 'المغرب' && category !== 'للبيع' && categoryRedux) {
        this.setState({
          categoryTitreFr: categoryRedux.urlFrancais,
          categoryTitreAr: categoryRedux.urlArabe,
          regionTitreFr: 'maroc',
          regionTitreAr: 'المغرب',
        });
      } else if (region !== 'المغرب' && category !== 'للبيع' && categoryRedux && regionRedux) {
        this.setState({
          categoryTitreFr: categoryRedux.urlFrancais,
          categoryTitreAr: categoryRedux.urlArabe,
          regionTitreFr: regionRedux.urlFr,
          regionTitreAr: regionRedux.urlArab,
          regionObject: regionRedux,
        });
      } else if (region === 'المغرب' && category === 'للبيع') {
        this.setState({
          categoryTitreFr: 'a_vendre',
          categoryTitreAr: 'للبيع',
          regionTitreFr: 'maroc',
          regionTitreAr: 'المغرب',
        });
      }
    }

    window.localStorage.setItem(
      regionCategorySearchKey,
      JSON.stringify({
        regionCategorySearch: {
          regionArab: regionRedux ? regionRedux.urlArab : '',
          regionFr: regionRedux ? regionRedux.urlFr : '',
          categorieArab: categoryRedux ? categoryRedux.urlArabe : '',
          categorieFr: categoryRedux ? categoryRedux.urlFrancais : '',
        },
      })
    );
  }

  getSearch() {
    const {
      category,
      query,
      price,
      titleOnly,
      urgentOnly,
      showAdvanceSearch,
      lang,
      marque,
      modele,
      typeCarburant,
      boiteVitesse,
      puissanceFiscale,
      anneeModele,
      categoryObject,
      marqueObject,
      modeleObject,
      boiteVitesseObject,
      typeCarburantObject,
    } = this.state;
    let { region } = this.state;
    const { history } = this.props;
    let { searchClicked } = this.state;

    return (
      <SearchF
        value={{
          region,
          category,
          query,
          price,
          titleOnly,
          urgentOnly,
          showAdvanceSearch,
          marque,
          modele,
          typeCarburant,
          boiteVitesse,
          puissanceFiscale,
          anneeModele,
          categoryObject,
          marqueObject,
          modeleObject,
          boiteVitesseObject,
          typeCarburantObject,
        }}
        style={{
          backgroundImage: 'url(/assets/img/flaha2.webp)',
          backgroundPositionY: '-16rem',
        }}
        styleForm={{ minHeight: '8rem' }}
        withAnnounce
        onChange={(searchCriteria: SearchCriteria) => {
          const {
            region,
            subCategory,
            query,
            price,
            titleOnly,
            urgentOnly,
            showAdvanceSearch,
            marque,
            modele,
            typeCarburant,
            boiteVitesse,
            puissanceFiscale,
            anneeModele,
            subCategoryObject,
            marqueObject,
            modeleObject,
            boiteVitesseObject,
            typeCarburantObject,
          } = searchCriteria;
          let { category, categoryObject } = searchCriteria;
          const constantAllRegion = lang === 'fr' ? 'maroc' : 'المغرب';
          const constantAllCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
          category = subCategory ? subCategory : category;
          categoryObject = subCategoryObject ? subCategoryObject : categoryObject;
          trackEvent(
            searchButtonClicked({
              region,
              category,
              query,
              price,
              titleOnly,
              urgentOnly,
              marque,
              modele,
              typeCarburant,
              boiteVitesse,
              puissanceFiscale,
              anneeModele,
              context: 'listing',
            })
          );

          let cityID, regionID;
          const citiesRedux = this.props.citiesRedux;
          let cityRedux;
          cityRedux =
            lang === 'ar'
              ? citiesRedux?.find((item) => item.urlArab === region)
              : citiesRedux?.find((item) => item.urlFr === region);

          cityID = cityRedux?.id;
          regionID = cityRedux?.region?.id;

          const options = {
            headers: {
              'Content-Type': 'application/json',
            },
          };

          EventTrackingService.createEvent({
            data: {
              event: {
                id: constants.SEARCH_BUTTON_CLICKED,
              },
              context: {
                id: constants.LISTING,
              },
              region: regionID
                ? {
                    id: regionID,
                  }
                : null,
              ville: cityID
                ? {
                    id: cityID,
                  }
                : null,
              categorie: categoryObject
                ? {
                    id: categoryObject?.id,
                  }
                : null,
              query: query,
              maxPrice: price?.upper,
              minPrice: price?.lower,
              marque: marqueObject
                ? {
                    id: marqueObject?.id,
                  }
                : null,
              modele: modeleObject
                ? {
                    id: modeleObject?.id,
                  }
                : null,
              typeDeCarburant: typeCarburantObject
                ? {
                    id: typeCarburantObject?.id,
                  }
                : null,
              boiteAVitesse: boiteVitesseObject
                ? {
                    id: boiteVitesseObject?.id,
                  }
                : null,
              puissanceFiscale: puissanceFiscale,
              anneeModele: anneeModele,
            },
            options: options,
          }).catch((reason) => console.error(reason.message));

          this.setState({
            region,
            category,
            query,
            price,
            titleOnly,
            urgentOnly,
            showAdvanceSearch,
            searchClicked: searchClicked + 1,
            marque,
            modele,
            typeCarburant,
            boiteVitesse,
            puissanceFiscale,
            anneeModele,
            categoryObject,
            marqueObject,
            modeleObject,
            boiteVitesseObject,
            typeCarburantObject,
          });
          this.props.setShowSearchModal(false);
          if (this.state.categoryObject?.id !== categoryObject?.id) this.props.setCounterValue(0);
          else this.props.increment();
          localStorage.removeItem('@flaha.marques');
          const params = {
            mar: marque,
            mod: modele,
            tc: typeCarburant,
            bv: boiteVitesse,
            pf: puissanceFiscale,
            am: anneeModele,
            minP: price?.lower,
            maxP: price?.upper,
          };
          const searchString = qs.stringify(params, { skipNull: true, skipEmptyString: true, sort: false });
          history.push({
            pathname: `/${lang}/${region ? region : constantAllRegion}/${category ? category : constantAllCategories}/${
              query ? query.replace(/ /g, '_') : ''
            }`,
            search: searchString,
            state: {
              region: region,
              category: category,
              query: query,
              price: price,
              titleOnly: titleOnly,
              urgentOnly: urgentOnly,
              showAdvanceSearch: showAdvanceSearch,
              marque,
              modele,
              typeCarburant,
              boiteVitesse,
              puissanceFiscale,
              anneeModele,
              categoryObject,
              marqueObject,
              modeleObject,
              boiteVitesseObject,
              typeCarburantObject,
            },
          });
        }}
      />
    );
  }

  // getSubSearch() {
  //     const {results} = this.state;

  //     return (<SubSearchForm
  //         results={results}
  //         onChange={(type) => {
  //             this.setState({type: type}, () => {
  //                 this.metaTags();
  //                 if (this.mainSearchResults) {
  //                     const {query, category, region, price, titleOnly, urgentOnly, type} = this.state;
  //                     this.mainSearchResults.update({
  //                         query,
  //                         category,
  //                         region,
  //                         price,
  //                         titleOnly,
  //                         urgentOnly,
  //                         type
  //                     });
  //                 }
  //             });
  //         }}
  //     />)
  // }

  getBreadCrumbs() {
    const { region, category } = this.state;
    // @ts-ignore
    const lang = this.props.match.params.lang;
    const allRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    let rootUrl = lang === 'fr' ? `/${lang}/maroc/a_vendre/` : `/${lang}/المغرب/للبيع/`;
    let lastLink = null;
    let preLastLink = null;

    let categoryRedux, regionRedux, cityRedux, provinceRedux, formattedRegion, formattedCategory, url;

    const { regionsRedux, citiesRedux, provincesRedux } = this.props;
    if (region && region !== allRegion && regionsRedux) {
      regionRedux =
        lang === 'ar'
          ? regionsRedux.find((item) => item.urlArab === region)
          : regionsRedux.find((item) => item.urlFr === region);

      cityRedux =
        lang === 'ar'
          ? citiesRedux.find((item) => item.urlArab === region)
          : citiesRedux.find((item) => item.urlFr === region);
      provinceRedux =
        lang === 'ar'
          ? provincesRedux?.find((item) => item.urlArab === region)
          : provincesRedux?.find((item) => item.urlFr === region);

      if (regionRedux) {
        formattedRegion = lang === 'fr' ? regionRedux.nomFr : regionRedux.nomArab;
      } else if (cityRedux) {
        formattedRegion = lang === 'fr' ? cityRedux.nomFr : cityRedux.nomArab;
      } else if (provinceRedux) {
        formattedRegion = lang === 'fr' ? provinceRedux.nomFr : provinceRedux.nomArab;
      }
    }

    const { categoriesRedux } = this.props;
    if (category && category !== allCategories && categoriesRedux) {
      lang === 'ar'
        ? (categoryRedux = categoriesRedux.find((item) => item.urlArabe === category))
        : (categoryRedux = categoriesRedux.find((item) => item.urlFrancais === category));
      formattedCategory = lang === 'fr' ? categoryRedux.titreFrancais : categoryRedux.titreArabe;
    }

    if (lang === 'fr') {
      if (region === allRegion && category === allCategories) {
      } else if (region !== allRegion && category === allCategories) {
        url = `/${lang}/${region}/a_vendre/`;
        lastLink = (
          <Link color="inherit" href={url}>
            {formattedRegion}
          </Link>
        );
      } else if (region === allRegion && category !== allCategories) {
        url = `/${lang}/maroc/${category}/`;
        lastLink = (
          <Link color="inherit" href={url}>
            {formattedCategory}
          </Link>
        );
      } else if (region !== allRegion && category !== allCategories) {
        const regionUrl = `/${lang}/${region}/a_vendre/`;
        url = `/${lang}/${region}/${category}/`;
        lastLink = (
          <Link color="inherit" href={url}>
            {formattedRegion + ' - ' + formattedCategory}
          </Link>
        );
        preLastLink = (
          <Link color="inherit" href={regionUrl}>
            {formattedRegion}
          </Link>
        );
      }
    } else {
      if (region === allRegion && category === allCategories) {
      } else if (region !== allRegion && category === allCategories) {
        url = `/${lang}/${region}/للبيع/`;
        lastLink = (
          <Link color="inherit" href={url}>
            {formattedRegion}
          </Link>
        );
      } else if (region === allRegion && category !== allCategories) {
        url = `/${lang}/المغرب/${category}/`;
        lastLink = (
          <Link color="inherit" href={url}>
            {formattedCategory}
          </Link>
        );
      } else if (region !== allRegion && category !== allCategories) {
        const regionUrl = `/${lang}/${region}/للبيع/`;
        url = `/${lang}/${region}/${category}/`;
        lastLink = (
          <Link color="inherit" href={url}>
            {formattedRegion + ' - ' + formattedCategory}
          </Link>
        );
        preLastLink = (
          <Link color="inherit" href={regionUrl}>
            {formattedRegion}
          </Link>
        );
      }
    }

    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" href="/">
          <IonIcon icon={home} style={{ padding: '0px 5px 0px 5px' }}></IonIcon>
          <FormattedMessage id={'home'} />
        </Link>
        <Link color="inherit" href={rootUrl}>
          <FormattedMessage id={allRegion} />
        </Link>
        {preLastLink}
        {lastLink}
      </Breadcrumbs>
    );
  }

  render() {
    const {
      query,
      region,
      category,
      price,
      titleOnly,
      urgentOnly,
      searchClicked,
      lang,
      marque,
      modele,
      typeCarburant,
      boiteVitesse,
      puissanceFiscale,
      anneeModele,
      categoryObject,
      totalElements,
      marqueObject,
      modeleObject,
    } = this.state;
    const styleDesktop = {
      backgroundImage: 'url(/assets/img/wave.svg)',
      backgroundRepeat: 'no-repeat',
    };
    const styleMobile = {
      backgroundImage: 'url(/assets/img/wave.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
    return (
      <IonPage>
        {this.metaTags()}
        <Header />
        <DFPSlotsProvider dfpNetworkId="22666931002">
          <IonContent className={'my-ion-content'} ref={this.myRef}>
            <div style={isPlatform('desktop') ? styleDesktop : styleMobile}>
              <div className={'d-flex justify-content-center p-3'}>
                {isProduction() ? (
                  !isPlatform('desktop') ? (
                    <AdSlot
                      sizes={[[300, 50]]}
                      adUnit={categoryObject?.adUnits?.bannerMobileAdviewPage || 'banner_search_mobile_adview_page'}
                    />
                  ) : (
                    <AdSlot sizes={[[970, 250]]} adUnit={categoryObject?.adUnits?.bannerMasthead || 'banner_search'} />
                  )
                ) : null}
              </div>
              {!isPlatform('desktop') ? (
                <div>
                  <div className="serach-button-container">
                    <IonButton color="light-tint" onClick={() => this.props.setShowSearchModal(true)}>
                      <IonIcon color="primary" size="large" icon={searchOutline} />
                      <p>
                        <FormattedMessage id={'form.search'} />
                      </p>
                    </IonButton>
                  </div>
                  {this.props.showSearchModalRedux && (
                    <div className="hide-button-container">
                      <IonButton
                        fill={'clear'}
                        color="primary"
                        onClick={() => this.props.setShowSearchModal(false)}
                        icon-only
                      >
                        <IonIcon icon={chevronUpOutline} />
                      </IonButton>
                    </div>
                  )}
                </div>
              ) : (
                this.getSearch()
              )}
              {!isPlatform('desktop') && this.props.showSearchModalRedux && this.getSearch()}

              {/*<div className={'container'}>
                    <h1><FormattedMessage id={'search.annonces'}/>
                        {tmpCategory  ? ` ${lang === 'fr' ? tmpCategory.titreFrancais : tmpCategory.titreArabe}` : ''}
                        {query ? ` « ${query} »` : ''}
                        {tmpRegion ? `: ${lang === 'fr' ? tmpRegion.nomFr : tmpRegion.nomArab}` : ''}
                    </h1>
                </div>*/}
              {/* <div className={'container'}>
                    <div className={'topAdContainer'}>
                        <div style={{
                            height: '115px',
                            backgroundColor: '#d9d9d9',
                            width: '100%',
                            textAlign: 'center',
                            lineHeight: '115px',
                            color: '#fff',
                            fontSize: '45px'
                        }}>Ad
                        </div>
                    </div>
                </div> */}
              <div className={'d-none d-md-flex justify-content-center mb-4'}>
                {isProduction() && isPlatform('desktop') && (
                  <AdSlot
                    sizes={[[970, 90]]}
                    adUnit={categoryObject?.adUnits?.bannerBillboard || 'banner_search_billboard'}
                  />
                )}
              </div>
            </div>
            {categoryObject?.neuf && marqueObject && modeleObject && (
              <div className={'container'}>
                <div className="row justify-content-start">
                  <div className="col-md-8">
                    <IonCard className={'p-3'}>
                      <IonRouterLink
                        color={'primary'}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          const params = {
                            cat: lang === 'fr' ? categoryObject?.urlFrancais : categoryObject?.urlArabe,
                            mar: lang === 'fr' ? marqueObject?.titreFr : marqueObject?.titreAr,
                            mod: lang === 'fr' ? modeleObject?.titreFr : modeleObject?.titreAr,
                          };

                          const searchString = qs.stringify(params, {
                            skipNull: true,
                            skipEmptyString: true,
                            sort: false,
                          });
                          this.props.history.push({
                            pathname: `/${lang}/neuf/`,
                            search: searchString,
                          });
                        }}
                      >
                        <span>
                          <IonIcon icon={megaphoneOutline} className={'mx-1'} />
                          <FormattedMessage
                            id={'flaha.neuf.modele.detail.title'}
                            values={{
                              marque: lang === 'fr' ? marqueObject?.titreFr : marqueObject?.titreAr,
                              modele: lang === 'fr' ? modeleObject?.titreFr : modeleObject?.titreAr,
                            }}
                          />
                        </span>
                      </IonRouterLink>
                    </IonCard>
                  </div>
                </div>
              </div>
            )}
            <div className={'container'}>
              <div className="row justify-content-start">
                <div className="col-md-8 col-sm-12">{!isPlatform('capacitor') ? this.getBreadCrumbs() : null}</div>
              </div>
              {!isPlatform('desktop') && <PremiumPostsSlide categoryId={categoryObject?.id} lang={this.state.lang} />}
              <div className={'row pt-2'} id={'main-result'}>
                <div className={'col-md-9 col-sm-12'}>
                  <MainResults
                    // @ts-ignore
                    //ref={(ref: any) => {
                    //    this.mainSearchResults = ref;
                    //}}
                    value={{
                      query,
                      category,
                      region,
                      price,
                      titleOnly,
                      urgentOnly,
                      marque,
                      modele,
                      typeCarburant,
                      boiteVitesse,
                      puissanceFiscale,
                      anneeModele,
                    }}
                    history={this.props.history}
                    searchClicked={searchClicked}
                    // @ts-ignore
                    lang={this.props.match.params.lang}
                  />
                  {categoryObject && (
                    <AccordionFAQ
                      lang={lang}
                      categoriesRedux={this.props.categoriesRedux}
                      countAnnonce={totalElements}
                      categoryObject={categoryObject}
                      region={region}
                      category={lang === 'fr' ? categoryObject?.titreFrancais : categoryObject?.titreArabe}
                    />
                  )}
                </div>
                <div className={'col-md-3 col-sm-12'}>
                  {isProduction() && isPlatform('desktop') && (
                    <div className={'d-flex justify-content-center mb-4'}>
                      <AdSlot
                        sizes={[[300, 250]]}
                        adUnit={categoryObject?.adUnits?.bannerPave || 'banner_search_pave'}
                      />
                    </div>
                  )}
                  <div className={'p-sticky-top mb-md-4'} style={{maxWidth: '340px'}}>
                    {isPlatform('desktop') && <PremiumAnnonces lang={lang} categoryId={categoryObject?.id} />}
                    {isProduction() && isPlatform('desktop') && (
                      <div className={'d-flex justify-content-center my-4'}>
                        <AdSlot
                          sizes={[[300, 600]]}
                          adUnit={categoryObject?.adUnits?.bannerGrandAngle || 'banner_search_grand_angle'}
                        />
                      </div>
                    )}
                    {isProduction() && !isPlatform('desktop') && (
                      <div className={'d-flex justify-content-center my-4'}>
                        <AdSlot
                          sizes={[[300, 50]]}
                          adUnit={categoryObject?.adUnits?.bannerMobileAdviewPage || 'banner_search_mobile_adview_page'}
                        />
                      </div>
                    )}
                  </div>
                  {/* <div className={'topAdContainer'}>
                                <div style={{
                                    height: '230px',
                                    backgroundColor: '#d9d9d9',
                                    width: '100%',
                                    textAlign: 'center',
                                    lineHeight: '230px',
                                    color: '#fff',
                                    fontSize: '45px'
                                }}>Ad
                                </div>
                            </div> */}
                </div>
              </div>
            </div>
            <CategoriesListSearch region={region} lang={lang} />
            {isPlatform('mobileweb') && <PopularTags lang={lang} />}
            <Footer copyrightOnly />
            {!isPlatform('desktop') && (
              <IonFab
                className="fab-button"
                vertical="bottom"
                horizontal={lang === 'fr' ? 'end' : 'start'}
                slot="fixed"
              >
                <IonFabButton mode={'ios'}>
                  <IonButton onClick={() => this.scrollToTop()}>
                    <IonIcon size={'large'} icon={searchOutline} />
                  </IonButton>
                </IonFabButton>
              </IonFab>
            )}
          </IonContent>
        </DFPSlotsProvider>
      </IonPage>
    );
  }

  metaTags() {
    const {
      query,
      category,
      region,
      metaTitle,
      metaDescription,
      lang,
      regionTitreFr,
      regionTitreAr,
      categoryTitreFr,
      categoryTitreAr,
      totalElements,
      categoryObject,
      regionObject,
    } = this.state;

    const metaTitre = lang === 'fr' ? metaTitle[0] : metaTitle[1];
    const metaDesc = lang === 'fr' ? metaDescription[0] : metaDescription[1];

    const formattedCategory = lang === 'fr' ? categoryObject?.titreFrancais : categoryObject?.titreArabe;
    const formattedRegion = lang === 'fr' ? regionObject?.nomFr : regionObject?.nomArab;

    const allRegion = lang === 'fr' ? 'maroc' : 'المغرب';
    const allCategories = lang === 'fr' ? 'a_vendre' : 'للبيع';
    const forUsed = lang === 'fr' ? " d'occasion" : ' مستعملة';

    const categoryUsedFormatted =
      categoryObject?.marques && !categoryObject?.forAnimal ? formattedCategory + forUsed : formattedCategory;
    let meta_Title;
    let meta_Description;

    const annonceAccount = totalElements ? totalElements.toString() : '';
    if (lang === 'fr') {
      if (query !== undefined && query !== '' && query !== null) {
        if (category !== allCategories && region === allRegion) {
          meta_Title = metaTitre.replace('({0})', query).replace('({1})', categoryUsedFormatted);
        } else if (region !== allRegion && category === allCategories) {
          meta_Title = metaTitre.replace('({0})', query).replace('({1})', formattedRegion);
        } else if (category !== allCategories && region !== allRegion) {
          meta_Title = metaTitre
            .replace('({0})', query)
            .replace('({1})', categoryUsedFormatted)
            .replace('({2})', formattedRegion);
        } else if (category === allCategories && region === allRegion) {
          meta_Title = metaTitre.replace('({0})', query).replace('({1})', annonceAccount);
        }
        meta_Description = metaDesc ? metaDesc.replace('({0})', query).replace('({1})', annonceAccount) : '';
      } else if (category !== allCategories && region === allRegion) {
        meta_Title = metaTitre.replace('({0})', categoryUsedFormatted);
        meta_Description = metaDesc
          ? metaDesc.replace('({0})', annonceAccount).replace('({1})', categoryUsedFormatted)
          : '';
      } else if (region !== allRegion && category === allCategories) {
        meta_Title = metaTitre.replace('({0})', formattedRegion);
        meta_Description = metaDesc ? metaDesc.replace('({0})', annonceAccount).replace('({1})', formattedRegion) : '';
      } else if (category !== allCategories && region !== allRegion) {
        meta_Title = metaTitre.replace('({0})', categoryUsedFormatted).replace('({1})', formattedRegion);
        meta_Description = metaDesc
          ? metaDesc
              .replace('({0})', '(' + formattedRegion + ')')
              .replace('({1})', categoryUsedFormatted)
              .replace('({2})', formattedRegion)
          : '';
      } else if (
        category === allCategories &&
        region === allRegion &&
        (query === undefined || query === null || query === '')
      ) {
        meta_Title = metaTitre;
        meta_Description = metaDesc;
      }
    } else {
      if (query !== undefined && query !== '' && query !== null) {
        if (category !== allCategories && region === allRegion) {
          meta_Title = metaTitre.replace('({0})', query).replace('({1})', categoryUsedFormatted);
        } else if (region !== allRegion && category === allCategories) {
          meta_Title = metaTitre.replace('({0})', query).replace('({1})', formattedRegion);
        } else if (category !== allCategories && region !== allRegion) {
          meta_Title = metaTitre
            .replace('({0})', query)
            .replace('({1})', categoryUsedFormatted)
            .replace('({2})', formattedRegion);
        } else if (category === allCategories && region === allRegion) {
          meta_Title = metaTitre.replace('({0})', query).replace('({1})', annonceAccount);
        }
        meta_Description = metaDesc ? metaDesc.replace('({0})', query).replace('({1})', annonceAccount) : '';
      } else if (category !== allCategories && region === allRegion) {
        meta_Title = metaTitre.replace('({0})', categoryUsedFormatted);
        meta_Description = metaDesc
          ? metaDesc.replace('({0})', annonceAccount).replace('({1})', categoryUsedFormatted)
          : '';
      } else if (region !== allRegion && category === allCategories) {
        meta_Title = metaTitre.replace('({0})', formattedRegion);
        meta_Description = metaDesc ? metaDesc.replace('({0})', annonceAccount).replace('({1})', formattedRegion) : '';
      } else if (category !== allCategories && region !== allRegion) {
        meta_Title = metaTitre.replace('({0})', categoryUsedFormatted).replace('({1})', formattedRegion);
        meta_Description = metaDesc
          ? metaDesc
              .replace('({0})', annonceAccount)
              .replace('({1})', categoryUsedFormatted)
              .replace('({2})', formattedRegion)
          : '';
      } else if (
        category === allCategories &&
        region === allRegion &&
        (query === undefined || query === null || query === '')
      ) {
        meta_Title = metaTitre;
        meta_Description = metaDesc;
      }
    }

    const urlPage = new URL(window.location.href);
    const page = parseInt(urlPage.searchParams.get('p'));
    const currentPageNumber = page ? page : 1;
    const currentPage = page ? `?p=${page}` : '';

    const url =
      lang === 'fr'
        ? `https://flaha.ma/fr/${regionTitreFr}/${categoryTitreFr}/${
            query ? query.replace(/ /g, '_') : ''
          }${currentPage}`
        : `https://flaha.ma/ar/${regionTitreAr}/${categoryTitreAr}/${
            query ? query.replace(/ /g, '_') : ''
          }${currentPage}`;

    const regionSearchUrl =
      lang === 'fr'
        ? `https://flaha.ma/fr/${regionTitreFr}/a_vendre/${query ? query.replace(/ /g, '_') : ''}${currentPage}`
        : `https://flaha.ma/ar/${regionTitreAr}/للبيع/${query ? query.replace(/ /g, '_') : ''}${currentPage}`;

    const mainUrl = `https://flaha.ma/ar/${regionTitreAr}/${categoryTitreAr}/${
      query ? query.replace(/ /g, '_') : ''
    }${currentPage}`;

    const alternateUrl = `https://flaha.ma/fr/${regionTitreFr}/${categoryTitreFr}/${
      query ? query.replace(/ /g, '_') : ''
    }${currentPage}`;

    document.querySelector('meta[property="og:title"]').setAttribute('content', meta_Title);
    document.querySelector('meta[property="og:description"]').setAttribute('content', meta_Description);
    document.querySelector('meta[property="og:url"]').setAttribute('content', url);

    const serachUrl = lang === 'fr' ? 'https://flaha.ma/fr/maroc/a_vendre' : 'https://flaha.ma/ar/المغرب/للبيع';
    const name = lang === 'fr' ? 'maroc' : 'المغرب';

    const itemListElement = [
      {
        type: 'ListItem',
        position: 1,
        name: 'Accueil',
        item: { '@type': 'thing', '@id': 'https://flaha.ma', name: 'Accueil' },
      },
      {
        type: 'ListItem',
        position: 2,
        name: name,
        item: { '@type': 'thing', '@id': serachUrl, name: name },
      },
      {
        type: 'ListItem',
        position: 3,
        name: formattedRegion,
        item: { '@type': 'thing', '@id': regionSearchUrl, name: formattedRegion },
      },
      {
        type: 'ListItem',
        position: 4,
        name: formattedCategory,
        item: { '@type': 'thing', '@id': url, name: formattedCategory },
      },
    ];

    const jsonLdData = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: itemListElement
        .filter((value) => {
          if (!formattedRegion) {
            return value.position !== 3;
          } else {
            return value;
          }
        })
        .map((value) => {
          if (value.position === 4 && !formattedRegion) {
            return { ...value, position: 3 };
          } else {
            return value;
          }
        }),
    };

    return (
      <Helmet>
        {/*<title>Flaha -
                Rechereche{query ? ' - ' + query : ''}{price ? (price.lower && price.upper ? ' | Price: ' + price.lower + ' - ' + price.upper : '') : ''}{category ? ' | ' + category : ''}{region ? ' | ' + region : ''}</title>*/}
        <title>{meta_Title}</title>
        <meta name="title" content={meta_Title} />
        <meta name="description" content={meta_Description} />
        <meta name="url" content={url} />
        <meta name="lang" content={lang} />
        {/*<link rel="canonical" hrefLang={'x-default'} href={url} />
        <link rel="alternate" hrefLang={hrefLangAlternate} href={alternateUrl} />*/}
        <link rel="canonical" href={url} />
        <link rel="alternate" hrefLang={'x-default'} href={mainUrl} />
        <link rel="alternate" hrefLang={'fr-MA'} href={alternateUrl} />
        <meta name="robots" content="index, follow" />
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(jsonLdData)}
        </script>
      </Helmet>
    );
  }
}

interface Params {
  query: string | null | undefined;
  region: string | null | undefined;
  category: string | null | undefined;
}

interface Props extends RouteComponentProps<Params>, FlahaProps {
  top?: boolean;
  regionsRedux: any;
  citiesRedux: any;
  provincesRedux;
  categoriesRedux: any;
  setShowModal: (showModal) => void;
  showSearchModalRedux: boolean;
  setShowSearchModal: (showSearchModal) => void;
  pageRedux: any;
  increment: () => void;
  setCounterValue: (value) => void;
}

interface State extends FlahaState {
  query?: string | null | undefined;
  category?: string | null | undefined;
  tmpCategory?: Category | null | undefined;
  region?: string | null | undefined;
  tmpRegion?: Region | null | undefined;
  type?: string | null | undefined;
  price?: {
    lower: number;
    upper: number;
  };
  titleOnly?: boolean;
  urgentOnly?: boolean;
  showAdvanceSearch: boolean;
  results?: number;
  searchClicked: number;
  metaTitle: [string, string];
  metaDescription: [string, string];
  regionTitreFr: string;
  regionTitreAr: string;
  categoryTitreFr: string;
  categoryTitreAr: string;
  totalElements: number;
  marque?: string | null | undefined;
  modele?: string | null | undefined;
  typeCarburant?: string | null | undefined;
  boiteVitesse?: string | null | undefined;
  puissanceFiscale?: string | null | undefined;
  anneeModele?: string | null | undefined;
  categoryObject?: any | null | undefined;
  marqueObject?: any | null | undefined;
  modeleObject?: any | null | undefined;
  typeCarburantObject?: any;
  boiteVitesseObject?: any;
  regionObject: any | null | undefined;
}

// Redux mappers
const mapStateToProps = (state) => {
    return {
        regionsRedux: state.saveRegionsCategoriesReducer.Regions_Categories.regions,
        citiesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.cities,
        provincesRedux: state.saveProvincesReducer.provinces.provinces,
        categoriesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.categories,
        showModalRedux: state.setShowModalReducer.showModalRedux.showModal,
        showSearchModalRedux: state.setShowSearchModalReducer.showSearchModalRedux.showSearchModal,
        pageRedux: state.searchRefreshCounterReducer.counter,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        increment: () => dispatch(increment()),
        setCounterValue: (value) => dispatch(setValue(value)),
        saveRegionsCategories: (regionsRedux, categoriesRedux) =>
            dispatch({
                type: 'SAVE_REGIONS_CATEGORIES',
                payload: {regions: regionsRedux, categories: categoriesRedux},
            }),
        setShowModal: (showModal) => dispatch({type: 'SET_SHOWMODAL', payload: {showModal: showModal}}),
        setShowSearchModal: (showSearchModal) =>
            dispatch({
                type: 'SET_SHOW_SEARCH_MODAL',
                payload: {showSearchModal: showSearchModal},
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
