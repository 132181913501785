import { IonCol, IonGrid, IonIcon, IonRow, isPlatform } from '@ionic/react';
import { logoFacebook, logoInstagram, logoLinkedin, logoYoutube } from 'ionicons/icons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { constants, copyright, Events } from 'utils';
import {
  getCategoriesReduxUtils,
  getParametrageReduxByCleUtils,
  getRegionsReduxUtils
} from 'utils/Functions/ReferentialGetters';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { Category, Region } from 'utils/Types/types';
import './Footer.scss';
import parametrageService from '../../services/ParametrageService';

export default class Footer extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    const { detail } = props;

    this.state = {
      showStore: !isPlatform('hybrid'),
      lang: 'ar',
      marginBottom: detail && window.innerWidth <= 767 ? '3rem' : '0rem',
      categories: [],
      regions: [],
      active: '',
      userToken: null,
      kitmediaActive: false,
    };
  }

  renderTopCategories(item: Category, index: number) {
    const { titreArabe, titreFrancais, id, urlFrancais, urlArabe } = item;
    const { lang } = this.state;
    const { onTopCategoryClick } = this.props;

    return (
      <li className={'nav-item'} key={id}>
        <a
          className={'nav-link'}
          href={lang === 'ar' ? `/ar/المغرب/${urlArabe}/` : `/fr/maroc/${urlFrancais}/`}
          onClick={() => onTopCategoryClick && onTopCategoryClick({ id })}
        >
          {lang === 'fr' ? titreFrancais : titreArabe}
        </a>
      </li>
    );
  }

  getToken() {
    this.setState({ userToken: localStorage.getItem('@flaha.userToken') });
  }

  renderRecentRegion(item: Region, index: number) {
    const { nomArab, nomFr, id, urlArab, urlFr } = item;
    const { lang } = this.state;
    const { onRecentRegionClick } = this.props;
    return (
      <li className={'nav-item'} key={id}>
        <a
          className={'nav-link'}
          href={lang === 'ar' ? `/ar/${urlArab}/للبيع` : `/fr/${urlFr}/${constants.allCategories}`}
          onClick={() => onRecentRegionClick && onRecentRegionClick({ id })}
        >
          {lang === 'fr' ? nomFr : nomArab}
        </a>
      </li>
    );
  }

  componentWillUnmount(): void {
    Events.destroy(constants.lang_change);
  }

  componentDidMount(): void {
    this.getToken();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    this.getCategories();
    this.getRegions();

    getParametrageReduxByCleUtils('KITMEDIA_FOOTER_IS_SHOWN').then((res) => {
      this.setState({ kitmediaActive: String(res.valeurFr).toLowerCase() === 'true' });
    });
  }

  getCategories() {
    const { copyrightOnly } = this.props;

    if (!copyrightOnly) {
      const categories = getCategoriesReduxUtils();
      const filtredCategories = categories && categories.filter((category) => category.sousCategorie === 0);
      const sortedCategories =
        filtredCategories &&
        filtredCategories.sort(function (a, b) {
          return a.orderAffichage - b.orderAffichage;
        });
      this.setState({
        categories: sortedCategories,
      });
    }
  }

  getRegions() {
    const { copyrightOnly } = this.props;

    if (!copyrightOnly) {
      const regions = getRegionsReduxUtils();
      const regionsCloned = [];
      regions && regions.forEach((val) => regionsCloned.push(Object.assign({}, val)));
      const splicedRegions = regionsCloned && regionsCloned.splice(0, 9);

      this.setState({
        regions: splicedRegions,
      });
    }
  }

  render() {
    let { showStore, marginBottom, regions, categories, active } = this.state;
    const { copyrightOnly } = this.props;
    let c = window.location.pathname;
    switch (c) {
      case '/fr/maroc':
        active = '/fr/maroc';
        break;
      case '/ar/المغرب':
        active = '/ar/المغرب';
        break;
      case '/sendPost':
        active = '/sendPost';
        break;
      case '/login':
        active = '/login';
        break;
      case '/user/profile':
        active = '/user/profile';
        break;
    }
    /*let mobileShow = <div className={'FooterMobile'}>
                            <IonRow>
                                <IonCol size={'4'}
                                 className={(active === '/ar/maroc' || active === '/fr/maroc') ? 'bg-col':''}>
                                    <Link to={ lang === 'ar' ? `/ar/maroc` : `/fr/maroc` }>
                                    <IonIcon icon={home} size={'large'}/>
                                    <IonText><FormattedMessage id={'annonces'}/></IonText>
                                    </Link>
                                </IonCol>
                                <IonCol size={'4'} className={active === '/sendPost' ? 'bg-col':''}>
                                    <Link to={'/sendPost'}>
                                        <IonIcon icon={addCircle} size={'large'}/>
                                        <IonText><FormattedMessage id={'depose'}/></IonText>
                                    </Link>
                                </IonCol>
                                {userToken === null ?(
                                <IonCol size={'4'} className={active === '/login' ? 'bg-col':''}>
                                    <Link to={'/login'}>
                                        <IonIcon icon={personCircleOutline} size={'large'}/>
                                        <IonText><FormattedMessage id={'menu.user.sign.in'}/></IonText>
                                    </Link>
                                </IonCol>):(
                                <IonCol size={'4'} className={active === '/user/profile' ? 'bg-col':''}>
                                    <Link to={'/user/profile'}>
                                        <IonIcon icon={personCircleOutline} size={'large'}/>
                                        <IonText><FormattedMessage id={'account'}/></IonText>
                                    </Link>
                                </IonCol>
                                )}
                            </IonRow>
                    </div>
      */
    return (
      <div>
        <footer
          className={'d-none d-md-block'}
          style={{
            marginBottom: marginBottom,
          }}
        >
          {!copyrightOnly ? (
            <IonGrid fixed={true}>
              <IonRow className={'foot-nav'}>
                <IonCol sizeMd={'3'}>
                  <a href={'/'}>
                    <picture>
                      <source type="image/webp" srcSet={'assets/flaha_blanc_mini.webp'} />
                      <img loading="lazy" src={'assets/flaha_blanc_mini.png'} alt={'Flaha.ma'} />
                    </picture>
                  </a>
                  {showStore ? (
                    <ul className={'storeIcons'}>
                      {/*<li>
                        <a href={'https://apps.apple.com/fr/app/flaha/id1566747294'}>
                          <picture>
                            <source type="image/webp" srcSet={'/assets/img/download_on_app_store.webp'} />
                            <img
                              loading="lazy"
                              src={'/assets/img/download_on_app_store.png'}
                              alt={'Flaha.ma Appstore'}
                            />
                          </picture>
                        </a>
                      </li>*/}
                      <li>
                        <a href={'https://play.google.com/store/apps/details?id=ma.flaha'}>
                          <picture>
                            <source type="image/webp" srcSet={'/assets/img/download_on_google_play.webp'} />
                            <img
                              loading="lazy"
                              src={'/assets/img/download_on_google_play.png'}
                              alt={'Flaha.ma Playstore'}
                            />
                          </picture>
                        </a>
                      </li>
                    </ul>
                  ) : null}
                </IonCol>
                <IonCol sizeMd={'3'}>
                  <ul className={'nav flex-column'}>
                    <li className={'nav-item'}>
                      <a className={'nav-link'} href={'/centre-aide'}>
                        <FormattedMessage id={'menu.help.center'} />.
                      </a>
                    </li>
                    <li className={'nav-item'}>
                      <a className={'nav-link'} href={'https://blog.flaha.ma/'}>
                        <FormattedMessage id={'menu.blog.info'} />.
                      </a>
                    </li>
                    <li className={'nav-item'}>
                      <a className={'nav-link'} href={'/sendPost'}>
                        <FormattedMessage id={'menu.post.add'} />.
                      </a>
                    </li>
                    {this.state.kitmediaActive && (
                      <li className={'nav-item'}>
                        <a
                          target={'_blank'}
                          className={'nav-link'}
                          href={process.env.REACT_APP_API_URL + constants.latestKitMedia}
                        >
                          <FormattedMessage id={'footer.kitmedia'} />.
                        </a>
                      </li>
                    )}
                  </ul>
                </IonCol>
                <IonCol sizeMd={'3'}>
                  <ul className={'nav flex-column'}>
                    <li className={'nav-item'}>
                      <a className={'nav-link'} href={'/contact'}>
                        <FormattedMessage id={'menu.contact.full'} />.
                      </a>
                    </li>
                    <li className={'nav-item'}>
                      <a className={'nav-link'} href={'/privacy'}>
                        <FormattedMessage id={'footer.term.condition'} />.
                      </a>
                    </li>
                    <li className={'nav-item'}>
                      <a className={'nav-link'} href={'/about'}>
                        <FormattedMessage id={'footer.about'} />
                      </a>
                    </li>
                    <li className={'nav-item'}>
                      <a className={'nav-link'} href={'https://flaha.ma/sitemap_index.xml'}></a>
                    </li>
                  </ul>
                </IonCol>
                <IonCol sizeMd={'3'}>
                  <ul className={'socialIcons'}>
                    <li>
                      <a href={'https://www.facebook.com/flahamaroc'}>
                        <IonIcon size={'large'} icon={logoFacebook} />
                      </a>
                    </li>
                    <li>
                      <a href={'https://www.instagram.com/flaha.ma'}>
                        <IonIcon size={'large'} icon={logoInstagram} />
                      </a>
                    </li>
                    <li>
                      <a href={'https://www.youtube.com/channel/UC_JjSDUwnwaeQG9VDG2R-cQ'}>
                        <IonIcon size={'large'} icon={logoYoutube} />
                      </a>
                    </li>
                    <li>
                      <a href={'https://www.linkedin.com/company/flaha-ma'}>
                        <IonIcon size={'large'} icon={logoLinkedin} />
                      </a>
                    </li>
                  </ul>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : null}
          <div
            style={{
              color: 'var(--ion-color-light)',
              backgroundColor: 'var(--ion-color-secondary-dark)',
              textAlign: 'center',
              padding: '.5rem 0',
            }}
          >
            © {copyright()}{' '}
            <a href={'/'}>
              {' '}
              <FormattedMessage id={'app.name'} />
            </a>
            , <FormattedMessage id={'footer.copyright'} />.
          </div>
        </footer>
        {/*!webOnly?mobileShow:null*/}
      </div>
    );
  }
}

interface Props extends FlahaProps {
  detail?: boolean;
  copyrightOnly?: boolean;
  token?: string | null;
  webOnly?: boolean;
  onTopCategoryClick?: Function;
  onRecentRegionClick?: Function;
}

interface State extends FlahaState {
  showStore: boolean;
  marginBottom: string;
  categories?: Array<Category>;
  regions?: Array<Region>;
  active: string;
  userToken: string | null;
  kitmediaActive: boolean;
}
