import http from '../http-common';
import { BoostRequest } from 'utils/Types/types';
import { constants } from 'utils';

class BoostRequestService {
  getAll(options) {
    return http.get(constants.boostRequestsAllGET, options);
  }

  get(id: string, options) {
    return http.get<BoostRequest>(constants.boostRequestsGET.replace('{id}', id), options);
  }

  /**
  getCreditCost(options) {
    return http.get(constants.boostRequestsCreditCostGET, options);
  }

  create(data: FundRequest, options) {
    return http.post(constants.boostRequestsPOST, data, options);
  }*/

  moderateBoostRequest(id: any, data, options) {
    return http.put(constants.boostRequestsPUT.replace('{id}', id), data, options);
  }

  getCountNotModerated(options) {
    return http.get(constants.boostRequestGetCountNotModerated, options);
  }

  delete(id: any, options) {
    return http.delete<any>(constants.boostRequestsDEL.replace('{id}', id), options);
  }
}

export default new BoostRequestService();
