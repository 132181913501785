import PersistedStore from '../../store/persistedStore';
import { constants } from 'utils';
import {Category, City, Parametrage, Province, Region} from '../Types/types';
import materialService from '../../services/MaterialService';

export async function saveCategoriesReduxUtils() {
  const url = process.env.REACT_APP_API_URL + constants.categories;
  const store = PersistedStore.getDefaultStore().store;

  await fetch(url)
    .then((result) => {
      return result.json();
    })
    .then((jsonResult) => {
      store.dispatch({
        type: 'SAVE_CATEGORIES',
        payload: { categories: jsonResult },
      });
    })
    .catch((error) => {
      console.error(error.message, error);
    });

  // omit rendering logic
}
export async function saveParametragesReduxUtils() {
  const url = process.env.REACT_APP_API_URL + constants.parametrageAllGET;
  const store = PersistedStore.getDefaultStore().store;

  await fetch(url)
    .then((result) => {
      return result.json();
    })
    .then((jsonResult) => {
      store.dispatch({
        type: 'SAVE_PARAMETRAGES',
        payload: jsonResult,
      });
    })
    .catch((error) => {
      console.error(error.message, error);
    });

  // omit rendering logic
}

export async function saveMarquesReduxUtils() {
  const url = process.env.REACT_APP_API_URL + constants.allMarquesGET;
  const store = PersistedStore.getDefaultStore().store;
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await materialService.getAllMarques(options);
    store.dispatch({
      type: 'SAVE_MARQUES',
      payload: res.data,
    });
  } catch (err) {
    console.error(err.message, err);
  }

  // omit rendering logic
}

export async function saveModelesReduxUtils() {
  const url = process.env.REACT_APP_API_URL + constants.allModelesGET;
  const store = PersistedStore.getDefaultStore().store;

  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await materialService.getAllModeles(options);
    store.dispatch({
      type: 'SAVE_MODELES',
      payload: res.data,
    });
  } catch (err) {
    console.error(err.message, err);
  }

  // omit rendering logic
}

export function getCategoriesReduxUtils() {
  const state = PersistedStore.getDefaultStore().store.getState();
  const categoriesRedux: Array<Category> = state && state.saveCategoriesReducer.categories.categories;
  return categoriesRedux;
}

export async function saveCitiesReduxUtils() {
  const store = PersistedStore.getDefaultStore().store;
  const url = process.env.REACT_APP_API_URL + constants.cities;

  await fetch(url)
    .then((result) => {
      return result.json();
    })
    .then((jsonResult) => {
      store.dispatch({
        type: 'SAVE_CITIES',
        payload: { cities: jsonResult },
      });
    })
    .catch((error) => {
      console.error(error.message, error);
    });
}

export function getCitiesReduxUtils() {
  const state = PersistedStore.getDefaultStore().store.getState();
  const citiesRedux: Array<City> = state && state.saveCitiesReducer.cities.cities;
  return citiesRedux;
}

export async function getParametrageReduxByCleUtils(cle: string) {
  const state = PersistedStore.getDefaultStore().store.getState();
  const parametragesRedux: Array<Parametrage> = state && state.saveParametragesReducer.parametrages;
    const parametrage = parametragesRedux.find((parametrage) => parametrage.cle === cle);
  return parametrage;
}

export async function saveProvincesReduxUtils() {
  const store = PersistedStore.getDefaultStore().store;
  const url = process.env.REACT_APP_API_URL + constants.provinces;

  await fetch(url)
    .then((result) => {
      return result.json();
    })
    .then((jsonResult) => {
      store.dispatch({
        type: 'SAVE_PROVINCES',
        payload: { provinces: jsonResult },
      });
    })
    .catch((error) => {
      console.error(error.message, error);
    });
}

export function getProvincesReduxUtils() {
  const state = PersistedStore.getDefaultStore().store.getState();
  const provincesRedux: Array<Province> = state && state.saveProvincesReducer.provinces.provinces;
  return provincesRedux;
}

export async function saveRegionsReduxUtils() {
  const store = PersistedStore.getDefaultStore().store;
  const url = process.env.REACT_APP_API_URL + constants.regions;

  await fetch(url)
    .then((result) => {
      return result.json();
    })
    .then((jsonResult) => {
      store.dispatch({
        type: 'SAVE_REGIONS',
        payload: { regions: jsonResult },
      });
    })
    .catch((error) => {
      console.error(error.message, error);
    });
}

export function getRegionsReduxUtils() {
  const state = PersistedStore.getDefaultStore().store.getState();
  const regionsRedux: Array<Region> = state && state.saveRegionsReducer.regions.regions;
  return regionsRedux;
}
