import { createReducer } from '@reduxjs/toolkit';
import { saveParametrages } from '../actions/saveParametrages';

const parametrages = [];

export const saveParametragesReducer = createReducer(
  { parametrages },
  {
    [saveParametrages.type]: (state, action) => ({
      ...state,
        parametrages: [...action.payload],
    }),
  }
);
