import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { IonIcon } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import { getMaxAndMinPriceByCategory, getQAReduxParametre } from 'utils/Functions/Functions';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { FAQPage } from 'schema-dts';
import { capitalize } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '20px 0',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
    },
    accordion: {
      backgroundColor: '#fafbfd',
    },
  })
);

interface AccordionFAQProps {
  lang: string;
  categoriesRedux: any;
  countAnnonce: number;
  category: string;
  categoryObject: any;
  region: string;
}

const AccordionFAQ: React.FC<AccordionFAQProps> = ({
  lang,
  categoriesRedux,
  countAnnonce,
  category,
  categoryObject,
  region,
}) => {
  const classes = useStyles();
  const [questionsAndAnswersFAQ, setQuestionsAndAnswersFAQ] = useState([]);
  const [minPriceCategory, setMinPriceCategory] = useState(undefined);
  const [maxPriceCategory, setMaxPriceCategory] = useState(undefined);

  const [expanded, setExpanded] = useState<string | false>(false);

  const formattedRegion = region ? region.replace(/-/g, ' ').replace(/_/g, ' ').replace(/[0-9]/g, '') : null;

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getMaxAndMinPriceByCategoryResult = (categoryId: number) => {
    getMaxAndMinPriceByCategory(categoryId).then((value) => {
      setMaxPriceCategory(value.maxPrice);
      setMinPriceCategory(value.minPrice);
    }).catch(reason => {
      /*setMaxPriceCategory(value.maxPrice);
      setMinPriceCategory(value.minPrice);*/
      console.error(reason.message);
    });
  };

  const getQuestionsAndAnswers = () => {
    getQAReduxParametre('FAQ_FIRST_QUESTION', 'FAQ_FIRST_ANSWER').then((value) => {
      if (value) {
        setQuestionsAndAnswersFAQ((prevState) => [
          ...prevState,
          {
            question: value.question,
            answer: value.answer,
          },
        ]);
      }
    });
    getQAReduxParametre('FAQ_SECOND_QUESTION', 'FAQ_SECOND_ANSWER').then((value) => {
      if (value) {
        setQuestionsAndAnswersFAQ((prevState) => [
          ...prevState,
          {
            question: value.question,
            answer: value.answer,
          },
        ]);
      }
    });
    getQAReduxParametre('FAQ_THIRD_QUESTION', 'FAQ_THIRD_ANSWER').then((value) => {
      if (value) {
        setQuestionsAndAnswersFAQ((prevState) => [
          ...prevState,
          {
            question: value.question,
            answer: value.answer,
          },
        ]);
      }
    });

    getQAReduxParametre('FAQ_FOURTH_QUESTION', 'FAQ_FOURTH_ANSWER').then((jsonRequest) => {
      if (jsonRequest) {
        setQuestionsAndAnswersFAQ((prevState) => [
          ...prevState,
          {
            question: jsonRequest.question,
            answer: jsonRequest.answer,
          },
        ]);
      }
    });

    getQAReduxParametre('FAQ_FIFTH_QUESTION', 'FAQ_FIFTH_ANSWER').then((jsonRequest) => {
      if (jsonRequest) {
        setQuestionsAndAnswersFAQ((prevState) => [
          ...prevState,
          {
            question: jsonRequest.question,
            answer: jsonRequest.answer,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    getQuestionsAndAnswers();
    getMaxAndMinPriceByCategoryResult(categoryObject.id);
  }, []);

  return (
    questionsAndAnswersFAQ.length > 0 &&
    !categoryObject.disableDefaultFAQ && (
      <div className={classes.root}>
        <h2>
          <FormattedMessage id={'title.faq'} />
        </h2>
        {!categoryObject.disableDefaultFAQ
          ? []
              .concat(questionsAndAnswersFAQ)
              .sort((a, b) => a.question?.id - b.question?.id)
              .map((value, index) => {
                if (value.question?.id === 68) {
                  const question = lang === 'fr' ? value?.question?.valeurFr : value?.question?.valeurAr;
                  const questionFormatted = question
                    ?.replace('({0})', category)
                    .replace('({1})', capitalize(formattedRegion));

                  const answer = lang === 'fr' ? value?.answer?.valeurFr : value?.answer?.valeurAr;
                  const answerFormatted = answer
                    ?.replace('({0})', category)
                    .replace('({1})', countAnnonce)
                    .replace('({2})', capitalize(formattedRegion));
                  return (
                    <div key={`accordion_default_${index}`}>
                      <Helmet
                        key={Math.random()}
                        script={[
                          helmetJsonLdProp<FAQPage>({
                            '@context': 'https://schema.org',
                            '@type': 'FAQPage',
                            '@id': window.location.pathname.toString(),
                            mainEntity: {
                              '@type': 'Question',
                              name: questionFormatted.toString(),
                              acceptedAnswer: {
                                '@type': 'Answer',
                                text: answerFormatted.toString(),
                              },
                            },
                          }),
                        ]}
                      />
                      <Accordion
                        className={classes.accordion}
                        key={`accordion_FAQ_${index}`}
                        expanded={expanded === `default-panel${index}`}
                        onChange={handleChange(`default-panel${index}`)}
                      >
                        <AccordionSummary
                          expandIcon={<IonIcon icon={chevronDown} />}
                          aria-controls={`default-panel${index}bh-content`}
                          id={`default-panel${index}bh-header`}
                        >
                          <Typography className={classes.heading}>{questionFormatted}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{answerFormatted}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                }
                if (value.question?.id === 70) {
                  const question = lang === 'fr' ? value?.question?.valeurFr : value?.question?.valeurAr;
                  const questionFormatted = question
                    ?.replace('({0})', category)
                    .replace('({1})', capitalize(formattedRegion));

                  const answer = lang === 'fr' ? value?.answer?.valeurFr : value?.answer?.valeurAr;
                  const answerFormatted = answer
                    ?.replace('({0})', category)
                    .replace('({1})', minPriceCategory)
                    .replace('({2})', maxPriceCategory)
                    .replace('({3})', capitalize(formattedRegion));
                  return (
                    <div key={`accordion_default_${index}`}>
                      <Helmet
                        key={Math.random()}
                        script={[
                          helmetJsonLdProp<FAQPage>({
                            '@context': 'https://schema.org',
                            '@type': 'FAQPage',
                            '@id': window.location.pathname.toString(),
                            mainEntity: {
                              '@type': 'Question',
                              name: questionFormatted.toString(),
                              acceptedAnswer: {
                                '@type': 'Answer',
                                text: answerFormatted.toString(),
                              },
                            },
                          }),
                        ]}
                      />
                      <Accordion
                        className={classes.accordion}
                        key={`accordion_FAQ_${index}`}
                        expanded={expanded === `default-panel${index}`}
                        onChange={handleChange(`default-panel${index}`)}
                      >
                        <AccordionSummary
                          expandIcon={<IonIcon icon={chevronDown} />}
                          aria-controls={`default-panel${index}bh-content`}
                          id={`default-panel${index}bh-header`}
                        >
                          <Typography className={classes.heading}>{questionFormatted}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{answerFormatted}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                }
                const question = lang === 'fr' ? value?.question?.valeurFr : value?.question?.valeurAr;
                const answer = lang === 'fr' ? value?.answer?.valeurFr : value?.answer?.valeurAr;
                return (
                  <div key={`accordion_default_${index}`}>
                    <Helmet
                      key={Math.random()}
                      script={[
                        helmetJsonLdProp<FAQPage>({
                          '@context': 'https://schema.org',
                          '@type': 'FAQPage',
                          '@id': window.location.pathname.toString(),
                          mainEntity: {
                            '@type': 'Question',
                            name: question.toString(),
                            acceptedAnswer: {
                              '@type': 'Answer',
                              text: answer.toString(),
                            },
                          },
                        }),
                      ]}
                    />
                    <Accordion
                      className={classes.accordion}
                      key={`accordion_FAQ_${index}`}
                      expanded={expanded === `default-panel${index}`}
                      onChange={handleChange(`default-panel${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<IonIcon icon={chevronDown} />}
                        aria-controls={`default-panel${index}bh-content`}
                        id={`default-panel${index}bh-header`}
                      >
                        <Typography className={classes.heading}>{question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })
          : null}
        {[].concat(categoryObject.categoryFAQs).map((value, index) => {
          const question = lang === 'fr' ? value?.questionFr : value?.questionAr;
          const answer = lang === 'fr' ? value?.answerFr : value?.answerAr;
          return (
            <div key={`accordion_custom_${index}`}>
              <Helmet
                key={Math.random()}
                script={[
                  helmetJsonLdProp<FAQPage>({
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    '@id': window.location.pathname.toString(),
                    mainEntity: {
                      '@type': 'Question',
                      name: question.toString(),
                      acceptedAnswer: {
                        '@type': 'Answer',
                        text: answer.toString(),
                      },
                    },
                  }),
                ]}
              />
              <Accordion
                className={classes.accordion}
                key={`accordion_FAQ_${index}`}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<IonIcon icon={chevronDown} />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography className={classes.heading}>{question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    )
  );
};

export default AccordionFAQ;
