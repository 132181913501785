import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonText } from '@ionic/react';
import { addCircleOutline, homeOutline, personCircleOutline } from 'ionicons/icons';
import React, { Component, lazy } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { constants, Events } from 'utils';
import { getLanguage, removeUserProfile, removeUserToken } from 'utils/Functions/Functions';
import { FlahaState } from 'utils/Interfaces/Interfaces';
import './Navigation.scss';
import { lang } from 'utils/Types/types';
import FormChat from 'pages/FormChat/FormChat';

const About = lazy(() => import('pages/About/About'));
const Contact = lazy(() => import('pages/Contact/Contact'));
const Login = lazy(() => import('pages/Login/Login'));
//const Register = lazy(() => import('pages/Register/Register'));
const ResetPassword = lazy(() => import('pages/ResetPassword/ResetPassword'));
const UpdateShop = lazy(() => import('pages/UpdateShop/UpdateShop'));
const Funds = lazy(() => import('pages/Funds/Funds'));
const MaterialDetail = lazy(() => import('pages/MaterialDetail/MaterialDetail'));
const CreateAdManagerContact = lazy(() => import('pages/CreateAdManagerContact/CreateAdManagerContact'));
const ShopDashboard = lazy(() => import('pages/ShopDashboard/ShopDashboard'));
const SheepLandingPage = lazy(() => import('pages/SheepLandingPage/SheepLandingPage'));
const PaymentInfo = lazy(() => import('pages/PaymentInfo/PaymentInfo'));

class Navigation extends Component<Props, State> {
  link = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      token: null,
    };
    this.checkRole = this.checkRole.bind(this);
    this.getInitialLanguage();
  }

  async getInitialLanguage() {
    await getLanguage().then((result: lang) => {
      this.setState({ lang: result });
    });
  }

  componentDidMount(): void {
    this.getToken();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  getToken() {
    this.setState({ token: localStorage.getItem('@flaha.userToken') });
  }

  removeToken() {
    removeUserToken();
    removeUserProfile();
    const url = `${process.env.REACT_APP_API_URL}${constants.logout}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers,
    }).then((result) => {
      if (result.status === 200) {
      }
    });
    this.setState({ token: null });
  }

  checkRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.forEach((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  render() {
    const { token, lang } = this.state;

    // @ts-ignore
    const { intl } = this.props;

    const baseURL = process.env.REACT_APP_ADMIN_SUB_DOMAIN_URL;
    const mainURL = process.env.REACT_APP_MAIN_DOMAIN_URL;

    return (
      <>
        <IonTabs>
          <IonRouterOutlet id={'app'} dir={lang === 'fr' ? 'ltr' : 'rtl'}>
            <Route
              exact
              path={['/:lang/:region/:category/:query', '/:lang/:region/:category/']}
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* login */}
            <Route
              path="/login"
              render={(props: RouteComponentProps) => {
                const { match, location, history, staticContext } = props;
                // @ts-ignore
                const { callback } = match.params;

                return token ? (
                  (window.location.href = '/')
                ) : (
                  <Login
                    match={match}
                    location={location}
                    history={history}
                    staticContext={staticContext}
                    callback={callback}
                  />
                );
              }}
            />
            {/*FacebookLogin
            <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>*/}
            {/* mes annonces */}
            <Route
              path="/user/mes-annonces"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />

            {/* Chat */}
            {/* Chat

            <Route
              exact
              path="/user/chat"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <Chat /> : <Redirect to="/" />;
              }}
            />*/}
            {/* ChatMessage*/}

            {/* annonces moderator*/}
            <Route
              exact
              path="/user/verify-annonces"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* desactiver les annonces*/}
            <Route
              exact
              path="/user/desactiver-annonces"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Modérer les annonces en masse */}
            <Route
              path="/moderator/modifier-annonces-en-masse"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Modérer les annonces */}
            <Route
              path="/moderator/moderate-annonces"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/moderator/liste-contacts"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/moderator/liste-kitmedia"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/moderator/shops"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/moderator/fund-requests"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/moderator/boost-requests"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/ouvrir-boutique"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/*<Route
              path={'/demande-abonnement'}
              render={() => {
                return token ? <CreateSubscription lang={lang} intl={intl} token={token} /> : <Redirect to="/login" />;
              }}
            />*/}
            <Route
              path={'/demande-solde'}
              render={() => {
                return token ? (
                  <Funds lang={lang} intl={intl} token={token} />
                ) : (
                  <Redirect to="/login?callback=/demande-solde" />
                );
              }}
            />
            {/* Modifier Annonce */}
            <Route
              exact
              path="/:lang/user/annonce/edit/:slug"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Chat Annonce */}
            <Route
              exact
              path="/:lang/user/messagerie/form/:slug"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <FormChat /> : <Redirect to="/" />;
              }}
            />
            {/* Flaha neuf Home */}
            <Route
              exact
              path={'/:lang/neuf/'}
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Material Detail */}
            <Route
              exact
              path={'/:lang/neuf/:vilReg/:cat/:mar/:mod/:slug'}
              render={(props) => {
                return <MaterialDetail intl={intl} token={token} {...props} />;
              }}
            />
            {/* shops Home */}
            <Route
              exact
              path={'/:lang/boutiques/'}
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shop Detail */}
            <Route
              exact
              path={'/:lang/:slug.htm'}
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shop update */}
            <Route
              exact
              path={'/:lang/user/shop/edit/:id'}
              render={(props) => {
                const callback = `/${lang}/user/shop/edit/${props.match.params.id}`;
                return token ? (
                  <UpdateShop lang={lang} intl={intl} token={token} {...props} />
                ) : (
                  <Redirect to={`/login?callback=${callback}`} />
                );
              }}
            />
            {/* shops user */}
            <Route
              path="/user/mes-boutiques"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shop Dashboard */}
            <Route
              path="/user/boutique-dashboard/:id?"
              render={(props) => {
                const callback = `/user/boutique-dashboard?shopID=${props.match.params.id}`;
                const encodedCallback = encodeURIComponent(callback);
                if (props.match.params.id) {
                  return token ? (
                    <Redirect to={`/user/boutique-dashboard?shopID=${props.match.params.id}`} />
                  ) : (
                    <Redirect to={`/login?callback=${encodedCallback}`} />
                  );
                } else {
                  const callback = `/user/boutique-dashboard${props.location.search}`;
                  const encodedCallback = encodeURIComponent(callback);
                  return token ? (
                    <ShopDashboard lang={lang} intl={intl} token={token} {...props} />
                  ) : (
                    <Redirect to={`/login?callback=${encodedCallback}`} />
                  );
                }
              }}
            />
            {/* analytics Dashboard */}
            <Route
              path="/user/analytics-dashboard"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* analytics Dashboard */}
            <Route
              path="/user/leads-moderation"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* contact commercial*/}
            <Route
              exact
              path="/:lang/contact-commercial/:slug.htm"
              render={(props) => {
                return <CreateAdManagerContact intl={intl} {...props} />;
              }}
            />
            {/* sheep landing page */}
            <Route
              path="/souk-el-haouli"
              render={() => {
                return <SheepLandingPage lang={lang} intl={intl} />;
              }}
            />
            {/* payment info */}
            <Route
              path="/paiement-infos"
              render={() => {
                return <PaymentInfo lang={lang} intl={intl} />;
              }}
            />
            {/* Modifier Annonce et modérer */}
            <Route
              exact
              path="/:lang/moderator/annonce/edit/:slug"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* profile */}
            <Route
              path="/user/profile"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* changePassword 
            <Route
              path="/user/changepassword"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token ? <ChangePassword /> : <Redirect to="/" />;
              }}
            />*/}
            {/* logout */}
            <Route
              path="/logout"
              render={(props) => {
                this.removeToken();
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/register"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/centre-aide"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />

            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/resetpassword/:token" component={ResetPassword} />

            <Route
              exact
              path="/:lang/:region/:ville/:category/:slug.htm"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route path="/about" component={About} />
            <Route
              path="/privacy"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              exact
              path={['/contact', '/contact-signaler/:id', '/contact-publicite/:subject']}
              render={(props: RouteComponentProps) => {
                // console.log(props);
                // @ts-ignore
                //  const {match, location, history, staticContext} = props;
                if (props) {
                  return (
                    <Contact
                      match={props.match}
                      location={props.location}
                      history={props.history}
                      staticContext={props.staticContext}
                    />
                  );
                }
                return <Contact />;
              }}
            />

            <Route
              path="/sendPost"
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />

            <Route
              path="/"
              exact
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />

            <Route
              path="/ar"
              exact
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />

            <Route
              path="/fr"
              exact
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
            />

            <Route
              path={[
                '/user/verify/annonces/:category/:query/:annonceId',
                '/user/verify/annonces/:category/:query/',
                '/user/verify/annonces/:category//:annonceId',
                '/user/verify/annonces/:category/',
              ]}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              render={(props) => {
                window.location.href = `${mainURL}${props.location.pathname}`;
                return null;
              }}
              exact
            />
          </IonRouterOutlet>

          <IonTabBar slot="bottom">
            <IonTabButton tab="search" href={lang === 'ar' ? `/ar/المغرب/للبيع/` : `/fr/maroc/a_vendre/`}>
              <IonIcon icon={homeOutline} />
              <IonText>
                <FormattedMessage id={'annonces'} />
              </IonText>
            </IonTabButton>
            <IonTabButton tab="sendPost" href="/sendPost">
              <IonIcon icon={addCircleOutline} />
              <IonText>
                <FormattedMessage id={'depose'} />
              </IonText>
            </IonTabButton>
            {token === null && (
              <IonTabButton tab="login" href="/login">
                <IonIcon icon={personCircleOutline} />
                <IonText>
                  <FormattedMessage id={'menu.user.sign.in'} />
                </IonText>
              </IonTabButton>
            )}
            {token !== null && (
              <IonTabButton tab="profile" href="/user/profile">
                <IonIcon icon={personCircleOutline} />
                <IonText>
                  <FormattedMessage id={'account'} />
                </IonText>
              </IonTabButton>
            )}
          </IonTabBar>
        </IonTabs>
      </>
    );
  }
}

interface Props {
  lang: lang;
}

interface State extends FlahaState {
  token: string | null | undefined;
}

// @ts-ignore
export default injectIntl(Navigation);
