import { combineReducers } from '@reduxjs/toolkit';
import { savePosReducer } from './savePosReducer';
import { saveRegionsCategoriesReducer } from './saveRegionsCategoriesReducer';
import { setShowModalReducer } from './setShowModalReducer';
import { setShowSearchModalReducer } from './setShowSearchModalReducer';
import { setSearchReducer } from './setSearchReducer';
import { saveCategoriesReducer } from './saveCategoriesReducer';
import { saveCitiesReducer } from './saveCitiesReducer';
import { saveProvincesReducer } from './saveProvincesReducer';
import { saveRegionsReducer } from './saveRegionsReducer';
import flahaNeufSearchReducer from '../slices/flahaNeufSearchSlice';
import searchRefreshCounterReducer from '../slices/searchRefreshCounterSlice';
import { saveModelesReducer } from './saveModelesReducer';
import { saveMarquesReducer } from './saveMarquesReducer';
import { saveParametragesReducer } from './saveParametragesReducer';

const rootReducer = combineReducers({
  savePosReducer: savePosReducer,
  saveRegionsCategoriesReducer: saveRegionsCategoriesReducer,
  setShowModalReducer: setShowModalReducer,
  setSearchReducer: setSearchReducer,
  setShowSearchModalReducer: setShowSearchModalReducer,
  saveCategoriesReducer: saveCategoriesReducer,
  saveMarquesReducer: saveMarquesReducer,
  saveModelesReducer: saveModelesReducer,
  saveCitiesReducer: saveCitiesReducer,
  saveProvincesReducer: saveProvincesReducer,
  saveRegionsReducer: saveRegionsReducer,
  saveParametragesReducer: saveParametragesReducer,
  flahaNeufSearchReducer: flahaNeufSearchReducer,
  searchRefreshCounterReducer: searchRefreshCounterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
