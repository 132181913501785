import React, { useEffect, useState } from 'react';
import { IonBadge } from '@ionic/react';
import materialService from '../../services/MaterialService';
import shopService from '../../services/ShopService';
import fundRequestService from '../../services/FundRequestService';
import boostRequestService from '../../services/BoostRequestService';

const NotificationTotal: React.FC = () => {
  const [countFundRequestNotification, setCountFundRequestNotification] = useState(0);
  const [countBoostRequestNotification, setCountBoostRequestNotification] = useState(0);
  const [countShopNotification, setCountShopNotification] = useState(0);
  const [countMaterialNotification, setCountMaterialNotification] = useState(0);

  useEffect(() => {
    const getCountFundRequestNotification = () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fundRequestService
        .getCountNotModerated(options)
        .then((value) => {
          const { data } = value;
          setCountFundRequestNotification(data.count);
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    };
    const getCountBoostRequestNotification = () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      boostRequestService
        .getCountNotModerated(options)
        .then((value) => {
          const { data } = value;
          setCountBoostRequestNotification(data.count);
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    };
    const getCountShopNotification = () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      shopService
        .getCountNotModerated(options)
        .then((value) => {
          const { data } = value;
          setCountShopNotification(data.count);
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    };
    const getCountMaterialNotification = () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      materialService
        .getCountNotModerated(options)
        .then((value) => {
          const { data } = value;
          setCountMaterialNotification(data.count);
        })
        .catch((reason) => {
          console.error(reason.message);
        });
    };

    getCountFundRequestNotification();
    getCountShopNotification();
    getCountMaterialNotification();
  }, []);

  return (
    countShopNotification + countMaterialNotification +
    countFundRequestNotification + countBoostRequestNotification > 0 && (
      <IonBadge color="danger">
        {countShopNotification + countMaterialNotification + countFundRequestNotification+ countBoostRequestNotification}
      </IonBadge>
    )
  );
};

export default NotificationTotal;
